import React from "react";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import $ from "jquery";
import ClipboardJS from "clipboard";

import Layout from "../components/layout";
import ThanksHeader from "../components/thanksHeader";
import Footer from "../components/footer";
import ThanksBar from "../components/thanksBar";

import "../components/base/_thanks.scss";
import TrackingTags from "../components/tracking_tags";

const config = require("../../gatsby-config");
const NoScript = require("react-noscript");

export default class ThanksPages extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  afterOpenModal() {
    $("#copy2").click(function() {
      $("#tooltip2").html("Kopyalandı");
    });
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    // Modal uyarisi icin
    Modal.setAppElement("body");

    // Kopyalama foknsiyonu
    new ClipboardJS(".link-stuff__link-button");

    // Sayfa yuklendiginde dso-bar dolduruluyor
    $(".city-abouts-dso-bar__filler").each(function() {
      $(this)
        .data("origWidth", $(this).width())
        .width(0)
        .animate(
          {
            width: $(this).data("origWidth"),
          },
          1200
        );
    });

    // Sayfa yuklendiginde ab-bar dolduruluyor
    $(".city-abouts-ab-bar__filler").each(function() {
      $(this)
        .data("origWidth", $(this).width())
        .width(0)
        .animate(
          {
            width: $(this).data("origWidth"),
          },
          1200
        );
    });

    // Istasyon animasyonu
    if ($(".city-abouts-station").length > 0) {
      // CANVAS
      var canvas = document.getElementById("bar"),
        width = canvas.width,
        height = canvas.height,
        cityStationNumber = $("#stationNumber").text(),
        cityDistrictNumber = $("#districtNumber").text(),
        cityStationRate = 0;

      if (cityStationNumber > 0) {
        cityStationRate = (cityStationNumber / cityDistrictNumber) * 100;

        // animasyonda sikinti cikmamasi icin (ayrica 100 uzerindeki degerler onemsiz)
        if (cityStationRate > 100) {
          cityStationRate = 100;
        }
      } else {
        cityStationRate = 0;
      }

      // CANVAS PROPERTIES
      var ctx = canvas.getContext("2d");
      ctx.lineWidth = 25.7;
      ctx.strokeStyle = "#66CC00";

      // CANVAS MATHS
      var x = width / 2,
        y = height / 2,
        radius = 34.5,
        circum = Math.PI * 2,
        start = Math.PI / -2, // Start position (top)
        finish = cityStationRate, // Finish (in %)
        curr = 0; // Current position (in %)

      // CANVAS ANIMATION

      // Enables browser-decided smooth animation (60fps)
      var raf =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;
      window.requestAnimationFrame = raf;

      // Animate function
      var animate = function(draw_to) {
        // Clear off the canvas
        ctx.clearRect(0, 0, width, height);
        // Start over
        ctx.beginPath();
        // arc(x, y, radius, startAngle, endAngle, anticlockwise)
        // Re-draw from the very beginning each time so there isn't tiny line spaces between each section (the browser paint rendering will probably be smoother too)
        ctx.arc(x, y, radius, start, draw_to, false);
        // Draw
        ctx.stroke();
        // Increment percent
        curr++;
        // Animate until end
        if (curr < finish + 1) {
          // Recursive repeat this function until the end is reached
          requestAnimationFrame(function() {
            animate((circum * curr) / 100 + start);
          });
        }
      };

      animate();
    }
  }

  render() {
    // Veri degiskenleri
    const {
      title = null,
      description = null,
      social = null,
      content = null,
    } = this.props.pageContext;

    return (
      <React.Fragment>
        <Helmet
          title={title}
          meta={[
            { name: "description", content: `${description}` },
            { name: "robots", content: "noindex,nofollow" },
          ]}
        >
          <html lang="tr" />
          <TrackingTags />
        </Helmet>
        {content &&
          content.map(content => (
            <Layout>
              <ThanksHeader />
              <main className="thanks-main">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 col-xs-12">
                      <img
                        className="city-values"
                        src={content.cityImage.src}
                        alt={content.cityImage.alt}
                      />
                      <div className="city-annual-average">
                        <span className="city-annual-average__text">
                          Yıllık{" "}
                          <strong>
                            PM 2.5<i>1</i>
                          </strong>{" "}
                          Ortalaması
                        </span>
                        <span className="city-annual-average__value">
                          <strong>
                            {content.cityPollutionValue === 0
                              ? "-"
                              : content.cityPollutionValue}{" "}
                          </strong>
                          µg/m³
                        </span>
                      </div>
                      <div className="city-abouts">
                        <div className="city-abouts-box">
                          <h3 className="city-abouts-box__title">
                            DSÖ<i>2</i> Sınır Değerlere Göre
                          </h3>
                          <div className="city-abouts-dso-bar">
                            <div
                              className={`city-abouts-dso-bar__filler ${
                                content.cityPollutionValue * 1.11111 > 10
                                  ? " city-abouts-dso-bar__filler-red"
                                  : ""
                              }`}
                              style={{
                                width: ` ${content.cityPollutionValue *
                                  1.11111}%`,
                              }}
                            ></div>
                            <div className="city-abouts-dso-bar__limit">
                              <span className="city-abouts-dso-bar__limit-text">
                                Sınır Değer
                                <br />
                                <strong>10 µg/m³</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="city-abouts-box">
                          <h3 className="city-abouts-box__title">
                            AB<i>3</i> Sınır Değerlere Göre
                          </h3>
                          <div className="city-abouts-ab-bar">
                            <div
                              className={`city-abouts-ab-bar__filler ${
                                content.cityPollutionValue * 1.11111 > 25
                                  ? " city-abouts-ab-bar__filler-red"
                                  : ""
                              }`}
                              style={{
                                width: `${content.cityPollutionValue *
                                  1.11111}%`,
                              }}
                            ></div>
                            <div className="city-abouts-ab-bar__limit">
                              <span className="city-abouts-ab-bar__limit-text">
                                Sınır Değer
                                <br />
                                <strong>25 µg/m³</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="city-abouts-box">
                          <h3 className="city-abouts-box__title">
                            Ölçüm İstasyon Sayısı
                          </h3>
                          <div className="city-abouts-box__info">
                            <div className="city-abouts-station">
                              <div className="progress">
                                <canvas
                                  id="bar"
                                  width="94px"
                                  height="94px"
                                ></canvas>
                              </div>
                              <span
                                id="stationNumber"
                                className="city-abouts-station__number"
                              >
                                {content.stationNumber}
                              </span>
                            </div>
                            <p className="city-abouts-box__text">
                              İlçe Sayısı:{" "}
                              <strong id="districtNumber">
                                {content.districtNumber}
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div className="city-abouts-box">
                          <h3 className="city-abouts-box__title">Notlar</h3>
                          <p
                            className="city-abouts-box__text"
                            dangerouslySetInnerHTML={{ __html: content.note }}
                          />
                        </div>
                      </div>
                      <div className="notes">
                        <ol className="notes__list">
                          <li className="notes__item">
                            2.5 mikrondan küçük hava kirleticilere partikül
                            madde 2.5 (PM 2.5) denir.
                          </li>
                          <li className="notes__item">Dünya Sağlık Örgütü</li>
                          <li className="notes__item">Avrupa Birliği</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <ThanksBar onClick={this.handleOpenModal} />
              <Footer />
              <Modal
                isOpen={this.state.showModal}
                className="Modal"
                overlayClassName="Overlay"
                onAfterOpen={this.afterOpenModal}
              >
                <div className="gp-modal">
                  <div className="gp-modal__header">
                    <div className="container">
                      <div className="row middle-xs">
                        <div className="logo-container col-md-6 col-sm-6 col-xs-6">
                          <a id="logo" className="thanks-header__logo" href="/">
                            <img
                              className="logo"
                              alt="Havada Kalmasın | Greenpeace"
                              src="/images/gp-logo.svg"
                            />
                          </a>
                        </div>
                        <div className="support-container col-md-6 col-sm-6 col-xs-6">
                          <a
                            className="thanks-header__support"
                            href="https://destek.greenpeace.org/havadakalmasin/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            DESTEK OL
                          </a>
                        </div>
                        <button
                          className="close-button"
                          onClick={this.handleCloseModal}
                        >
                          Kapat
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="gp-modal__content">
                    <div className="container">
                      <div className="row middle-xs">
                        <div className="col-md-10 col-sm-12 col-xs-12">
                          <p className="gp-modal__message">
                            {content.cityName} temiz hava karnesini
                            arkadaşlarınla paylaş, derin bir nefes al. Temiz
                            hava hakkımız havada kalmasın!
                          </p>
                          <div className="link-stuff">
                            <div className="link-stuff__social link-stuff__facebook">
                              <a
                                href={
                                  "https://facebook.com/sharer.php?u=" +
                                  encodeURIComponent(
                                    config.siteMetadata.siteUrl +
                                      "/" +
                                      content.cityPageSlug
                                  ) +
                                  "&quote=" +
                                  encodeURIComponent(social.facebookText)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Facebook’da Paylaş
                              </a>
                            </div>
                            <div className="link-stuff__social link-stuff__twitter">
                              <a
                                href={
                                  "https://twitter.com/share?url=" +
                                  encodeURIComponent(
                                    config.siteMetadata.siteUrl +
                                      "/" +
                                      content.cityPageSlug
                                  ) +
                                  "&text=" +
                                  encodeURIComponent(
                                    social.twitterText + " - "
                                  ) +
                                  "&via=" +
                                  social.twitterUser
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Twitter'da Paylaş
                              </a>
                            </div>
                            <div
                              id="link-stuff__link2"
                              className="link-stuff__link"
                            >
                              <button
                                id="copy2"
                                className="link-stuff__link-button"
                                data-clipboard-text={
                                  config.siteMetadata.siteUrl +
                                  "/" +
                                  content.cityPageSlug
                                }
                              >
                                Linki kopyala
                              </button>
                              <div id="tooltip2">Kopyala</div>
                            </div>

                            <div className="mobile">
                              <div className="wa">
                                <a
                                  href={
                                    "whatsapp://send?text=" +
                                    social.whatsappText +
                                    " https://act.gp/havada-kalmasin"
                                  }
                                >
                                  Whatsapp'ta Paylaş
                                </a>
                              </div>
                              <div className="fb">
                                <a
                                  href={
                                    "https://facebook.com/sharer.php?u=" +
                                    encodeURIComponent(
                                      config.siteMetadata.siteUrl +
                                        "/" +
                                        content.cityPageSlug
                                    ) +
                                    "&quote=" +
                                    encodeURIComponent(social.facebookText)
                                  }
                                >
                                  Facebook’da Paylaş
                                </a>
                              </div>
                              <div className="tw">
                                <a
                                  href={
                                    "https://twitter.com/share?url=" +
                                    encodeURIComponent(
                                      config.siteMetadata.siteUrl +
                                        "/" +
                                        content.cityPageSlug
                                    ) +
                                    "&text=" +
                                    encodeURIComponent(
                                      social.twitterText + " - "
                                    ) +
                                    "&via=" +
                                    social.twitterUser
                                  }
                                >
                                  Twitter'da Paylaş
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gp-modal__footer">
                    <div className="container">
                      <div className="row middle-xs">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <button
                            className="modal-close-button"
                            onClick={this.handleCloseModal}
                          >
                            Daha sonra paylaşacağım
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </Layout>
          ))}
        {/*<!-- Google Code for Iklim Imza Conversion Page -->*/}
        <script type="text/javascript">
          {`
          /* <![CDATA[ */
          var google_conversion_id = 866196640;
          var google_conversion_label = "hl6WCJOi04IBEKC5hJ0D";
          var google_remarketing_only = false;
          /* ]]> */
          `}
        </script>
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        ></script>
        <NoScript>
          <div style={{ display: "inline" }}>
            <img
              height="1"
              width="1"
              style={{ borderStyle: "none" }}
              alt=""
              src="//www.googleadservices.com/pagead/conversion/866196640/?label=hl6WCJOi04IBEKC5hJ0D&amp;guid=ON&amp;script=0"
            />
          </div>
        </NoScript>
      </React.Fragment>
    );
  }
}
