import React from "react";

import "./index.scss";

const ThanksHeader = () => (
  <header className="thanks-header">
    <div className="container">
      <div className="row middle-xs">
        <div className="logo-container col-md-3 col-sm-3 col-xs-6">
          <a id="logo" className="thanks-header__logo" href="index.html">
            <img
              className="logo"
              alt="Havada Kalmasın | Greenpeace"
              src="/images/gp-logo.svg"
            />
          </a>
        </div>
        <div className="steps-container col-md-6 col-sm-6 col-xs-12">
          <div className="steps">
            <div className="steps__step steps__step-active">1</div>
            <div className="steps__dots">
              <div className="steps__dot"></div>
              <div className="steps__dot"></div>
              <div className="steps__dot"></div>
              <div className="steps__dot"></div>
              <div className="steps__dot"></div>
            </div>
            <div className="steps__step">2</div>
          </div>
        </div>
        <div className="support-container col-md-3 col-sm-3 col-xs-6">
          <a
            className="thanks-header__support"
            href="https://destek.greenpeace.org/havadakalmasin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DESTEK OL
          </a>
        </div>
      </div>
    </div>
    <div className="thanks-header__message-container">
      <div className="container">
        <div className="row middle-xs center-xs">
          <div className="col-md-8 col-sm-12 col-xs-12">
            <h1 className="thanks-header__title">
              Kampanyamıza katıldığın için teşekkürler!
            </h1>
            <p className="thanks-header__message">
              Temiz hava hakkım havada kalmasın dedin, şimdi sen de şehrinin
              karnesini arkadaşlarınla paylaş desteğini tamamla.
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default ThanksHeader;
