import React from "react";

import "./index.scss";

const ThanksBar = ({ ...props }) => (
  <section className="thanks-bar">
    <div className="container row middle-xs">
      <button {...props} className="thanks-bar__button">
        KAMPANYAYI PAYLAŞ
      </button>
    </div>
  </section>
);

export default ThanksBar;
